import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

function TimeButton({
  slot, n, setHours, className, timeSelected, selectTime,
}) {
  const dateTime = DateTime.fromObject(slot);
  return (
    <>
      {/* eslint-disable-next-line react/no-array-index-key */}
      <span className="uk-width-1-2 uk-width-1-4@m uk-margin-small uk-margin-small-top" key={n}>
        <button
          type="button"
          className={`uk-button uk-width-1-1 ${className} ${timeSelected === n ? 'uk-section-primary' : ''}`}
          onClick={() => {
            selectTime(n);
            setHours(dateTime);
          }}
        >
          {DateTime.fromObject(slot).toLocaleString(DateTime.TIME_SIMPLE)}
        </button>
      </span>
    </>
  );
}

TimeButton.defaultProps = {
  slot: new Date(),
  n: null,
  timeSelected: -1,
  selectTime: () => null,
  setHours: () => null,
  className: '',
};

TimeButton.propTypes = {
  n: PropTypes.number,
  slot: PropTypes.instanceOf(Date),
  setHours: PropTypes.func,
  className: PropTypes.string,
  timeSelected: PropTypes.number,
  selectTime: PropTypes.func,
};

export default TimeButton;
