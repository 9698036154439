import { useHistory, useParams } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import axios from '../axios/axios-config';
import Flash from './Flash';
import ShopMeta from './ShopMeta';
import Error from './Error';

function Shop() {
  const { shopName } = useParams();
  const history = useHistory();

  const [content, setContent] = useState([]);
  const [displayError, setDisplayError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (window.location.host === 'waddle.to') {
      axios.get(`/booking/${shopName}`)
        .then((response) => {
          history.replace(`/book/${response.data.id}`);
        })
        .catch((error) => {
          setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios.get(`/shop/username/${shopName}`)
        .then((response) => {
          setContent(response.data);
        })
        .catch((error) => {
          setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const body = displayError
    ? <Error displayError={displayError} />
    : (
      <>
        <p>
          <h2>{content?.name}</h2>
        </p>
        <div className="uk-heading-divider uk-margin-bottom">
          <p>
            Phone:&nbsp;
            {content?.phoneNumber || 'Not Listed'}
          </p>
          <p>
            Email:&nbsp;
            {content?.email || 'Not Listed'}
          </p>
          <p className="display-linebreak">
            Address:&nbsp;
            {content?.address || 'Not Listed'}
          </p>
        </div>
        {content?.id && (
        <div className="uk-heading-divider uk-margin-bottom">
          <Flash shopId={content?.id} />
        </div>
        )}
        <div>
          <p>Want a custom piece or have questions?</p>
          <button className="uk-button uk-button-primary" type="button" onClick={() => history.push(`/${shopName}/contact`)}>Contact us!</button>
        </div>
      </>
    );

  return (
    <div className="App">
      <ShopMeta shopName={content?.name || shopName} />
      <div>
        {loading ? <p>LOADING...</p> : body}
      </div>
    </div>
  );
}

export default Shop;
