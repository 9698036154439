import React, { useEffect, useState } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import PropTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';
import { DateTime } from 'luxon';

function Calendar({ date, update, className }) {
  const [value, onChange] = useState(date);
  const [lxDate, setLxDate] = useState(DateTime.fromJSDate(date));

  useEffect(() => {
    const d = DateTime.fromJSDate(value);
    if (d.day !== lxDate.day || d.month !== lxDate.month || d.year !== lxDate.year) {
      update(d);
      setLxDate(d);
    }
  }, [value]);

  const tileDisabled = ({ date: chosenDate }) => chosenDate < DateTime.now().minus({ days: 1 }).endOf('day').toJSDate();

  return (
    <div>
      <ReactCalendar
        onChange={onChange}
        value={value}
        className={className}
        tileDisabled={tileDisabled}
      />
    </div>
  );
}

Calendar.defaultProps = {
  date: new Date(),
  update: () => null,
  className: '',
};

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date),
  update: PropTypes.func,
  className: PropTypes.string,
};

export default Calendar;
