import React from 'react';
import PropTypes from 'prop-types';

function Error({ className, displayError }) {
  return (
    <p className={`uk-alert ${className}`}>
      <b>Error:</b>
      {' '}
      {displayError}
      <br />
      Contact us at
      {' '}
      <a href="mailto:info@getwaddle.com">info@getwaddle.com</a>
      {' '}
      to learn more.
    </p>
  );
}

Error.defaultProps = {
  className: '',
  displayError: 'An error occured :(',
};

Error.propTypes = {
  className: PropTypes.string,
  displayError: PropTypes.string,
};

export default Error;
