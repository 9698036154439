import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Image from './Image';
import axios from '../axios/axios-config';

function Flash({ className, shopId }) {
  const history = useHistory();

  const [services, setServices] = useState([]);
  const [displayError, setDisplayError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios.get(`/booking/services/${shopId}`)
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => {
        setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const goTo = (id) => history.push(`${history.location.pathname}/${id}`);

  const body = () => {
    if (displayError) return <p className="uk-alert">{displayError}</p>;
    if (!services || services.length === 0) {
      return (
        <p className="uk-text-bold uk-text-left">
          Sorry, we don&apos;t have any Flash Art available at the moment.
        </p>
      );
    }
    return (
      <div className="uk-grid uk-height-1-1">
        {
          services && services.map((service, n) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={n} className="uk-width-1-2@s uk-width-1-4@m uk-width-1-4@l uk-margin-small uk-margin-small-top">
              <button
                type="button"
                className="uk-button-default uk-width-1-1 uk-height-1-1 uk-inline square-ratio"
                onClick={() => goTo(service.id)}
              >
                {service.images?.length > 0
                  ? (
                    <Image
                      imageId={service.images[0]}
                      artistId={service.artist}
                      imageType="gallery"
                      className="uk-width-1-1 square-aspect"
                    />
                  )
                // eslint-disable-next-line react/no-unknown-property
                  : (<span className="uk-width-1-1" uk-icon="icon: image" />)}
                <div className="uk-overlay uk-overlay-default uk-position-bottom uk-height-1-4 uk-padding-small">
                  <p className="uk-text-secondary uk-text-bottom uk-text-left uk-margin-remove">{service.title || ''}</p>
                  <p className="uk-text-bold uk-text-bottom uk-text-left uk-margin-remove">
                    $
                    {service.price || ' ASK'}
                  </p>
                </div>
              </button>
            </span>
          ))
        }
      </div>
    );
  };

  return (
    <div className={className}>
      <div className="uk-width-1-1@s uk-width-3-4@m uk-align-center">
        <h3 className="uk-text-left">Flash Tattoos</h3>
        {loading ? <p>LOADING FLASH ART...</p> : body()}
      </div>
    </div>
  );
}

Flash.defaultProps = {
  className: '',
  shopId: null,
};

Flash.propTypes = {
  className: PropTypes.string,
  shopId: PropTypes.string,
};

export default Flash;
