import PropTypes from 'prop-types';
import { S3 } from '../axios/config';

function Image({
  className, artistId, imageId, imageType,
}) {
  const imageUrl = `${S3}/${artistId}/${imageType}/${imageId}`;

  return (
    <div className="uk-container">
      <img src={imageUrl} loading="lazy" className={className} alt={`${imageType}-${imageId}`} />
    </div>
  );
}

Image.defaultProps = {
  className: '',
  artistId: null,
  imageId: null,
  imageType: null,
};

Image.propTypes = {
  className: PropTypes.string,
  artistId: PropTypes.string,
  imageId: PropTypes.string,
  imageType: PropTypes.string,
};

export default Image;
