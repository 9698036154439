import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

function Currency({ value, currency }) {
  const intl = useIntl();
  return (
    <>
      {new Intl.NumberFormat(intl.locale, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
      }).format(value)}
      &nbsp;
      {currency}
    </>
  );
}

Currency.defaultProps = {
  value: 0,
  currency: 'USD',
};

Currency.propTypes = {
  value: PropTypes.number,
  currency: PropTypes.string,
};

export default Currency;
