import logo from '../styles/logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Welcome to the waddle app!</p>
      </header>
    </div>
  );
}

export default App;
