import axios from 'axios';
import { BACKEND } from './config';

const url = window.location.hostname === 'localhost' ? 'http://localhost:8080' : BACKEND;

axios.defaults.baseURL = `${url}/api`;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = config.headers.Authorization || (token ? `Bearer ${token}` : '');
  return config;
}, (error) => Promise.reject(error));

axios.interceptors.response.use((response) => response, (error) => Promise.reject(error));

export default axios;
