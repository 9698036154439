import { useParams } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import axios from '../axios/axios-config';
import Currency from './Currency';
import ShopMeta from './ShopMeta';
import Error from './Error';

function CancelBooking() {
  const { bookingId } = useParams();

  const [shop, setShop] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [artist, setArtist] = useState(null);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [time, setTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [description, setDescription] = useState(null);
  const [displayError, setDisplayError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    setLoading(true);
    axios.get(`/booking/cancel/${bookingId}`)
      .then((response) => {
        setShop(response.data.shopName);
        setArtist(response.data.artistName);
        setPrice(response.data.price?.value);
        setCurrency(response.data.price?.currency);
        if (response.data.time?.date) setTime(DateTime.fromObject(response.data.time?.date));
        setDuration(response.data.time?.duration);
        setDescription(response.data.description);
      })
      .catch((error) => {
        setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const confirm = (e) => {
    e.preventDefault();
    if (!email) return;
    axios.post(`/booking/cancel/${bookingId}`, {
      email,
    })
      .then(() => {
        setConfirmed(true);
      })
      .catch((error) => {
        setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const body = displayError ? <Error displayError={displayError} /> : (
    <div className="uk-width-1-2 uk-align-center uk-text-center">
      <p>
        Shop:&nbsp;
        {shop}
      </p>
      <p>
        Artist:&nbsp;
        {artist}
      </p>
      <p>
        Price:&nbsp;
        {price ? <Currency value={price} currency={currency} /> : 'N/A'}
      </p>
      <p>
        Description:&nbsp;
        {description || 'N/A'}
      </p>
      <p>
        Duration:&nbsp;
        {duration || 'N/A'}
      </p>
      <p className="uk-text-center">
        Time:&nbsp;
        {time && time.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
      </p>
      {confirmed ? <h2>Booking Canceled</h2> : (
        <form
          name="form"
          className="uk-form-stacked"
          onSubmit={confirm}
        >
          <div className="uk-width-1-1 uk-align-center">
            <label htmlFor="email">
              <span className="uk-align-left uk-margin-remove">Email:</span>
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="uk-input uk-margin-bottom uk-width-1-1"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <span className="uk-align-left uk-text-left uk-text-small">* Please re-enter the email address used for this booking to confirm your cancellation</span>
            </label>
          </div>

          <button type="submit" className="uk-align-center uk-button uk-button-primary">
            Cancel
            Booking
          </button>
        </form>
      )}
    </div>
  );

  return (
    <div className="App">
      <ShopMeta shopName={shop} page="Cancel" description={`Cancel ${bookingId}`} />
      <header className="App-header uk-align-center uk-text-center">
        <p className="uk-align-center uk-text-center">
          Booking ID:&nbsp;
          {bookingId}
        </p>
        {loading ? <p>LOADING...</p> : body}
      </header>
    </div>
  );
}

export default CancelBooking;
