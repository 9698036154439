import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

function ShopMeta({ page, shopName, description }) {
  const { shopUsername } = useParams();

  const title = page ? `${page} | ${shopName}` : `${shopName} | powered by Waddle`;
  const descriptionBody = description || `Welcome to the shop of ${shopName}`;

  return (
    <Helmet>
      <title>
        {title}
      </title>
      <meta name="description" content={descriptionBody} />

      <meta name="og:title" content={title} />
      <meta name="og:description" content={descriptionBody} />
      <meta name="twitter:card" content="Book your next tattoo with us!" />
      <meta name="twitter:creator" content={shopUsername} />
      <meta name="twitter:title" content={`Book with ${shopName} via Waddle`} />
      <meta name="twitter:description" content={descriptionBody} />
    </Helmet>
  );
}

ShopMeta.defaultProps = {
  shopName: '',
  page: '',
  description: null,
};

ShopMeta.propTypes = {
  shopName: PropTypes.string,
  page: PropTypes.string,
  description: PropTypes.string,
};

export default ShopMeta;
