import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import axios from '../axios/axios-config';

function Upload({
  text, artistId, setImageIds, imageIds,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, onFileSelectError] = useState(null);

  const handleFileInput = (files) => {
    // handle validations
    let hasError = false;
    axios.all(
      Array.from(files).map((file) => {
        if (file.size > 1024 * 10000) {
          hasError = true;
          return {};
        }
        const data = new FormData();
        data.append('artistId', artistId);
        data.append('file', file, file.name);
        return axios.post('/image/contact', data)
          .then((response) => ({ imageId: response.data.imageId, name: file.name }))
          .catch(() => {
            onFileSelectError('Error Uploading Files :(');
            return {};
          });
      }),
    ).then((listA) => {
      const i = listA.map((obj) => obj.imageId);
      setImageIds([...imageIds, ...i]);
      setSelectedFiles([
        ...selectedFiles, ...listA,
      ]);
    });
    if (hasError) {
      setImageIds([]);
      setSelectedFiles(null);
      onFileSelectError({ msg: 'File size cannot exceed more than 10MB' });
    }
  };

  const updateInput = (imageId) => {
    const i = imageIds.filter((item) => item !== imageId);
    const s = selectedFiles.filter((item) => item.imageId !== imageId);
    setImageIds([...i]);
    setSelectedFiles(([...s]));
  };

  return (
    <div className="uk-placeholder uk-text-center">
      <Dropzone onDrop={handleFileInput}>
        {({ getRootProps, getInputProps }) => (
          <section>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <div {...getRootProps()}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <input {...getInputProps()} />
              {/* eslint-disable-next-line react/no-unknown-property */}
              <span uk-icon="icon: cloud-upload" />
              <span className="uk-text-middle">{text}</span>
            </div>
          </section>
        )}
      </Dropzone>
      {error && <p className="uk-alert">{error.msg}</p>}
      {selectedFiles && selectedFiles.length > 0 && (
      <p className="uk-alert">
          {selectedFiles.map((file) => (
            <span className="uk-display-inline">
              <p>{file.name}</p>
              {/* eslint-disable-next-line
              jsx-a11y/control-has-associated-label,react/no-unknown-property */}
              <button
                type="button"
                className="uk-button-small"
                onClick={() => updateInput(file.imageId)}
              >
                {/* eslint-disable-next-line react/no-unknown-property */}
                <span uk-icon="icon: close" />
              </button>
            </span>
          ))}
      </p>
      )}
    </div>
  );
}

Upload.defaultProps = {
  text: (
    <span>
      <b>Attach files by dropping them here</b>
      <br />
      or
      <br />
      {' '}
      <b>
        Click
        <b className="uk-button-link"> here </b>
        to select one
      </b>
    </span>
  ),
  artistId: null,
  setImageIds: null,
  imageIds: null,
};

Upload.propTypes = {
  text: PropTypes.instanceOf(Element),
  artistId: PropTypes.string,
  setImageIds: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  imageIds: PropTypes.array,
};

export default Upload;
