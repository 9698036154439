// import { useParams } from 'react-router-dom';

function Auth() {
  // const {} = useParams();

  return (
    <div className="App">
      <header className="App-header">
        <p className="uk-text-bold">Login Page Coming Soon ;)</p>
      </header>
    </div>
  );
}

export default Auth;
