import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Auth from './components/Auth';
import App from './components/App';
import Booking from './components/Booking';
import Shop from './components/Shop';
import CancelBooking from './components/CancelBooking';
import Contact from './components/Contact';

function Routes() {
  const routes = (
    <Switch>
      <Redirect from="/:url*(/+)" to={window.location.pathname.replace(/\/+$/, '')} />
      <Route exact path="/" component={App} />
      <Route path="/login" component={Auth} />
      <Route path="/cancel/:bookingId" component={CancelBooking} />
      <Route path="/:shopName/contact" component={Contact} />
      <Route path="/:shopName/:bookingId" component={Booking} />
      <Route path="/:shopName" component={Shop} />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <div className="uk-container">
      <header />
      <main style={{ height: '100%' }} className="uk-margin-large">
        {routes}
      </main>
      <footer className="uk-align-center uk-text-center uk-text-muted">
        Powered by Waddle © 2023
      </footer>
    </div>
  );
}

export default Routes;
