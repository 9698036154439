import { useParams } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import axios from '../axios/axios-config';
import Upload from './Upload';
import ShopMeta from './ShopMeta';

function Contact() {
  const { shopName } = useParams();

  const [shop, setShop] = useState(null);
  const [artistId, setArtist] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [budget, setBudget] = useState(null);
  const [description, setDescription] = useState('');
  const [displayError, setDisplayError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [imageIds, setImageIds] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    setLoading(true);
    axios.get(`/shop/username/${shopName}`)
      .then((response) => {
        setShop(response.data.name);
        setArtist(response.data.owner);
      })
      .catch((error) => {
        setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const confirm = (event) => {
    event.preventDefault();

    if (!name || !email || !artistId) {
      return;
    }
    axios.post('/booking/contact', {
      name,
      email,
      phone,
      budget: budget || 0,
      images: imageIds || [],
      description: description || '',
      artist: artistId,
    })
      .then(() => {
        setConfirmed(true);
      })
      .catch((error) => {
        setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const body = () => {
    if (displayError) return (<p className="uk-alert">{displayError}</p>);
    if (confirmed) return (<h2>Your message has been sent!</h2>);

    return (
      <div className="uk-width-1-1@s uk-width-3-2@m uk-align-center uk-text-center">
        <form
          name="form"
          className="uk-form-stacked"
          onSubmit={confirm}
        >
          <p>
            Contact&nbsp;
            <b>{shop}</b>
          </p>
          <div className="uk-width-1-1@s uk-width-1-2@m uk-align-center">
            <label htmlFor="name">
              <span className="uk-align-left uk-margin-remove">Name:</span>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="uk-input uk-margin-bottom uk-width-1-1"
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </label>
            <label htmlFor="phone">
              <span className="uk-align-left uk-margin-remove">
                Phone:
              </span>
              <input
                type="phone"
                name="phone"
                placeholder="Phone (optional)"
                className="uk-input uk-margin-bottom uk-width-1-1"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </label>
            <label htmlFor="email">
              <span className="uk-align-left uk-margin-remove">Email:</span>
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="uk-input uk-margin-bottom uk-width-1-1"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </label>
            <Upload artistId={artistId} imageIds={imageIds} setImageIds={setImageIds} />
            <label htmlFor="description">
              <span className="uk-align-left uk-margin-remove">Description:</span>
              <textarea
                name="description"
                placeholder="Description (optional)"
                className="uk-textarea uk-margin-bottom uk-width-1-1"
                required={false}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </label>
            <label htmlFor="budget">
              <span className="uk-align-left uk-margin-remove">Budget:</span>
              <input
                type="number"
                name="budget"
                placeholder="Budget (optional)"
                className="uk-input uk-margin-bottom uk-width-1-1"
                required={false}
                value={budget}
                onChange={(event) => setBudget(parseFloat(event.target.value))}
              />
            </label>

          </div>

          <button type="submit" className="uk-align-center uk-button uk-button-primary">
            Submit
          </button>
        </form>
      </div>
    );
  };

  return (
    <div className="App">
      <ShopMeta shopName={shop} page="Contact" description={`Contact ${shop}`} />
      <header className="App-header uk-align-center uk-text-center">
        {loading ? <p>LOADING...</p> : body()}
      </header>
    </div>
  );
}

export default Contact;
