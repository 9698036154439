import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import axios from '../axios/axios-config';
import Calendar from './Calendar';
import Currency from './Currency';
import TimeButton from './TimeButton';
import Image from './Image';
import ShopMeta from './ShopMeta';
import Error from './Error';
import { FRONTEND } from '../axios/config';

function Booking() {
  const { bookingId } = useParams();
  const history = useHistory();

  const [service, setService] = useState({});
  const [shop, setShop] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [artist, setArtist] = useState(null);
  const [artistId, setArtistId] = useState(null);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [time, setTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [displayError, setDisplayError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDates, setLoadingDates] = useState(true);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState(null);
  const [name, setName] = useState('');
  const [chosenDate, changeDate] = useState(null);
  const [timeslots, setTimeslots] = useState([]);
  const [images, setImages] = useState([]);
  const [hours, setHours] = useState(null);
  const [timeSelected, selectTime] = useState(-1);

  useEffect(() => {
    setLoading(true);
    axios.get(`/booking/${bookingId}`)
      .then((response) => {
        setService(response.data);
        setShop(response.data.shopName);
        setUsername(response.data.username);
        setArtist(response.data.artistName);
        setArtistId(response.data.artist);
        setPrice(response.data.price);
        setCurrency(response.data.currency);
        if (response.data.time?.date) {
          setTime(DateTime.fromObject(response.data.time?.date));
        }
        setDuration(response.data.time?.duration);
        setTitle(response.data.title);
        setDescription(response.data.description);
        setImages(response.data?.images || []);
        changeDate(DateTime.now());
      })
      .catch((error) => {
        setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (chosenDate && service?.artist) {
      setLoadingDates(true);
      axios.get(`/calendar/timeslots/${service.artist}?year=${chosenDate.year}&month=${chosenDate.month}&day=${chosenDate.day}&duration=${duration}`)
        .then((timeRes) => {
          setTimeslots(timeRes.data);
        })
        .catch((error) => {
          setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
        })
        .finally(() => {
          setLoadingDates(false);
        });
    }
  }, [chosenDate]);

  const confirm = () => {
    if (!chosenDate || !hours || !email || !name) return;
    axios.post(`/booking/book/${bookingId}`, {
      email,
      clientPhone: phone,
      clientName: name,
      year: chosenDate?.year,
      month: chosenDate?.month,
      day: chosenDate?.day,
      hour: hours.hour,
      minute: hours.minute,
    })
      .then((response) => {
        if (response.data.time?.date) setTime(DateTime.fromObject(response.data.time?.date));
        setDuration(response.data.time?.duration);
        setConfirmed(true);
      })
      .catch((error) => {
        setDisplayError(error.response?.data?.message || 'Error Loading Page :(');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTimes = () => {
    if (!timeslots || timeslots.length === 0) {
      return <p className="uk-text-center uk-width-1-1">No Times Available :(</p>;
    }
    return timeslots.map((slot, n) => (
      <TimeButton
        slot={slot}
        setHours={setHours}
        n={n}
        timeSelected={timeSelected}
        selectTime={selectTime}
      />
    ));
  };

  const body = displayError ? <Error displayError={displayError} /> : (
    <div className="uk-width-1-1 uk-align-center uk-text-left uk-width-1-1@s uk-width-3-4@m">
      <div className="uk-heading-divider uk-margin-bottom">
        <div className="uk-width-1-1 uk-width-3-4@m uk-align-center">
          <p>
            Tattoo:&nbsp;
            {title}
          </p>
          <p>
            Artist:&nbsp;
            {artist}
          </p>
          <p>
            Price:&nbsp;
            {price ? <Currency value={price} currency={currency} /> : 'TBD'}
          </p>
          <p className="display-linebreak">
            Description:&nbsp;
            {description || 'N/A'}
          </p>
          <p>
            Duration:&nbsp;
            {`${duration} minutes` || 'N/A'}
          </p>
        </div>
      </div>
      {images && (
      <div className="uk-heading-divider uk-align-center">
        <div className="uk-width-1-1@s uk-width-3-4@m uk-align-center">
          <h3 className="uk-text-left uk-padding-remove">Artwork</h3>
        </div>
        <div className="uk-align-center uk-width-1-1@s uk-width-3-4@m">
          <div className="uk-grid uk-width-1-1">
            {images.length > 0
              ? images.map((image, n) => (
              // eslint-disable-next-line react/no-array-index-key
                <div key={n} className="uk-width-1-2@s uk-width-1-3@m uk-width-1-3@l uk-margin-small uk-margin-small-top">
                  <Image imageId={image} artistId={artistId} imageType="gallery" className="uk-width-1-1" />
                </div>
              ))
              : (<p className="uk-text-left">No Artwork Available</p>)}
          </div>
        </div>
      </div>
      )}
      {!confirmed && (
        <div className="uk-width-1-1@s uk-width-3-4@m uk-align-center">
          <h3 className="uk-text-left uk-padding-remove">Confirm Booking</h3>
          <div className="uk-width-1-2@xl uk-align-center">
            <div>
              <label htmlFor="name">
                <span className="uk-align-left uk-margin-remove">Name:</span>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="uk-input uk-margin-bottom uk-width-1-1"
                  required
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </label>
              <label htmlFor="email">
                <span className="uk-align-left uk-margin-remove">Email:</span>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="uk-input uk-margin-bottom uk-width-1-1"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </label>
              <label htmlFor="phone">
                <span className="uk-align-left uk-margin-remove">Phone:</span>
                <input
                  type="phone"
                  name="phone"
                  placeholder="Phone"
                  className="uk-input uk-margin-bottom uk-width-1-1"
                  required={false}
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
      )}
      {!time && <Calendar update={changeDate} className="App-header uk-align-center uk-text-center" />}
      {(!confirmed && timeslots) && (
        <div className="uk-grid">
          {getTimes()}
        </div>
      )}
      {loadingDates && <p>LOADING...</p>}
      <p className="uk-text-center">
        Time:&nbsp;
        {(time || chosenDate)
            && (time || chosenDate).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
      </p>
      {!confirmed && (
      <button type="button" disabled={!hours || !name || !email} onClick={confirm} className="uk-align-center uk-button uk-button-primary">
        Confirm
        Booking
      </button>
      )}
      {confirmed && <h2 className="uk-text-center uk-text-uppercase uk-text-bold">Booking Confirmed</h2>}
    </div>
  );
  const inputRef = React.useRef(null);

  return (
    <div className="App">
      <ShopMeta shopName={shop} page={`${title || 'Booking'}`} description={`Booking at ${shop} for ${title}`} />
      <header className="App-header uk-align-center uk-text-center">
        <p className="uk-align-center uk-text-center uk-text-muted">
          Booking ID:&nbsp;
          {bookingId}
        </p>
        <div className="uk-align-center uk-width-3-4@m">
          <div className="uk-heading-divider">
            <h2>
              {/* eslint-disable-next-line max-len */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <b ref={inputRef} onClick={() => history.push(`${FRONTEND}/${username || ''}`)} className="uk-pointer">
                {shop || 'N/A'}
              </b>
            </h2>
          </div>
        </div>
        {loading ? <p>LOADING...</p> : body}
      </header>
    </div>
  );
}

export default Booking;
