import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/uikit-3.15.16/css/custom.css';
import './styles/uikit-3.15.16/css/uikit.min.css';
import './styles/index.css';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { IntlProvider } from 'react-intl';
import reportWebVitals from './reportWebVitals';
import Routes from './Route';

UIkit.use(Icons);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HttpsRedirect>
      <BrowserRouter>
        <IntlProvider defaultLocale="en-US" locale={navigator.language}>
          <Routes />
        </IntlProvider>
      </BrowserRouter>
    </HttpsRedirect>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
